import { useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { dataProvider } from 'providers/dataProvider';
import { JobRepairNoteDto, JobRepairNoteType } from '@vatos-pas/common';
import useIsMobile from 'hooks/isMobile';
import { getNoteType } from 'modules/master-sheet/utils/getNoteType';

type NotesManagementDialog = {
  handleClose: () => void;
  canAddNotes: boolean;
  selectedReceivable?: string;
} & DialogProps;

export const NotesManagementDialog = ({
  handleClose,
  canAddNotes,
  open,
  selectedReceivable,

  ...rest
}: NotesManagementDialog) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const isMobile = useIsMobile();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<JobRepairNoteDto[] | null>(null);
  const [note, setNote] = useState('');

  const descriptionNotes = notes?.filter(
    note => note.jobRepairNoteType === JobRepairNoteType.DESCRIPTION,
  );

  const submitData = {
    text: note,
    jobRepairId: selectedReceivable,
    jobRepairNoteType: JobRepairNoteType.NOTE_RECEIVABLE,
  };

  const getNotes = async () => {
    try {
      setLoading(true);
      const notesResponse = await dataProvider.getList<JobRepairNoteDto>(
        'job-repair-note',
        {
          filter: {
            jobRepairId: selectedReceivable,
            'jobRepairNoteType||$in': [
              JobRepairNoteType.NOTE_RECEIVABLE,
              JobRepairNoteType.DESCRIPTION,
            ],
          },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'createdAt', order: 'DESC' },
        },
      );

      setNotes(notesResponse.data);
    } catch (err) {
      notify('Failed to get notes', 'error');
    } finally {
      setLoading(false);
    }
  };

  const submitNote = async () => {
    try {
      await dataProvider.create('job-repair-note', {
        data: submitData,
      });

      if (notes?.length === 0) {
        refresh();
        handleClose();
      } else {
        getNotes();
      }

      setNote('');
      setNotes([]);
    } catch (err) {
      notify('Failed to create a note', 'error');
    }
  };

  useEffect(() => {
    if (selectedReceivable && open) {
      getNotes();
    }
  }, [open, selectedReceivable]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      {...rest}
    >
      <DialogContent>
        <Box width="100vw" pb={3} maxWidth={isMobile ? '60vw' : '35vw'}>
          {canAddNotes && (
            <Box>
              <TextField
                label="Notes"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                inputProps={{
                  maxLength: 999,
                }}
                value={note}
                onChange={event => setNote(event.target.value)}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" my={3}>
            {canAddNotes && (
              <Button
                disabled={note.length <= 0}
                onClick={submitNote}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            )}
            <Button
              onClick={() => {
                handleClose();
                setNote('');
                setNotes([]);
              }}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </Box>
          <Box>
            <Box borderTop={1} pt={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Notes History
              </Typography>
            </Box>
            {loading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            ) : (
              <>
                {notes?.map((item, index) => {
                  const isFirstDescription =
                    !!descriptionNotes?.length &&
                    item.id ===
                      descriptionNotes?.[descriptionNotes.length - 1].id;

                  const noteType = getNoteType(item, isFirstDescription) ?? '';

                  const isDescriptionRemoved =
                    noteType === 'Description Update' && !item.text?.length;

                  return (
                    <Box
                      key={item.id}
                      mb={2}
                      borderTop={index === 0 ? '' : 1}
                      borderColor="lightgray"
                      pt={2}
                    >
                      <Box>
                        <Typography variant="caption">{noteType}</Typography>
                        <Box>
                          <Typography>{`${item.user?.firstName} ${
                            item.user?.lastName
                          } - ${new Date(item.createdAt).toLocaleTimeString(
                            'en-US',
                          )} ${new Date(item.createdAt).toLocaleDateString(
                            'en-US',
                          )}`}</Typography>
                        </Box>
                        <Box mt={2}>
                          <Typography
                            className={
                              isDescriptionRemoved ? classes.italic : undefined
                            }
                          >
                            {isDescriptionRemoved
                              ? 'Description removed'
                              : item.text}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  italic: {
    fontStyle: 'italic',
  },
});

export default NotesManagementDialog;
