interface IErrors {
  name?: string[];
  shortName?: string[];
  repairsRate?: string[];
  garageCostFixed?: string[];
  hangingCostPerBoard?: string[];
  finishingCostPerBoard?: string[];
  sprayingCostPerBoard?: string[];
}

interface RegionValues {
  name: string;
  shortName: string;
  repairsRate: number;
  garageCostFixed: number;
  hangingCostPerBoard: number;
  finishingCostPerBoard: number;
  sprayingCostPerBoard: number;
}

export const validateRegion = async (
  values: RegionValues,
): Promise<IErrors> => {
  const errors: IErrors = {};
  if (!values.name) {
    errors.name = ['The Name is required'];
  }
  if (!values.shortName) {
    errors.shortName = ['The Shortname is required'];
  }
  if (values.repairsRate) {
    if (values.repairsRate < 1) {
      errors.repairsRate = ['The Repairs Rate must be at least 1'];
    }
    if (values.repairsRate > 99.99) {
      errors.repairsRate = ['The Repairs Rate must be 99.99 or less'];
    }
  } else {
    errors.repairsRate = ['The Repairs Rate is required'];
  }

  if (values.garageCostFixed && values.garageCostFixed < 0) {
    errors.garageCostFixed = ['The Garage (Fixed cost) must be at least 0'];
  }

  if (values.hangingCostPerBoard && values.hangingCostPerBoard < 0) {
    errors.hangingCostPerBoard = [
      'The Hanging (Cost per board) must be at least 0',
    ];
  }

  if (values.finishingCostPerBoard && values.finishingCostPerBoard < 0) {
    errors.finishingCostPerBoard = [
      'The Finishing (Cost per board) must be at least 0',
    ];
  }

  if (values.sprayingCostPerBoard && values.sprayingCostPerBoard < 0) {
    errors.sprayingCostPerBoard = [
      'The Spraying (Cost per board) must be at least 0',
    ];
  }

  return errors;
};
