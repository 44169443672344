import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Note';
import { Show } from 'components/Show';
import NotesHistory from './NotesHistory';
import { useState } from 'react';
import { Identifier } from 'react-admin';

type NotesManagementProps = {
  value: string;
  label?: string;
  disabled?: boolean;
  enableHistory?: boolean;
  jobRepairId?: Identifier;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

export const NotesManagement = ({
  value,
  label = 'Notes',
  disabled = false,
  enableHistory = false,
  jobRepairId,
  onChange,
}: NotesManagementProps) => {
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);

  const renderNotesHistory = enableHistory && !!jobRepairId;

  return (
    <Box width="100%" pb={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        mb={1}
      >
        <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
        <Show condition={renderNotesHistory}>
          <IconButton onClick={() => setIsHistoryDialogOpen(true)}>
            <NotesIcon />
          </IconButton>
        </Show>
      </Box>
      <Box>
        <TextField
          fullWidth
          multiline
          disabled={disabled}
          rows={6}
          variant="outlined"
          inputProps={{
            maxLength: 999,
          }}
          value={value}
          onChange={onChange}
        />
      </Box>
      <Show condition={renderNotesHistory}>
        <Dialog
          open={isHistoryDialogOpen}
          fullWidth
          maxWidth="md"
          onClose={() => setIsHistoryDialogOpen(false)}
          aria-labelledby="alert-dialog-title-"
          aria-describedby="alert-dialog-description9"
        >
          <DialogContent>
            <NotesHistory jobRepairId={jobRepairId} />
          </DialogContent>
          <DialogActions>
            <Box pb={2} pr={2}>
              <Button
                onClick={() => setIsHistoryDialogOpen(false)}
                color="primary"
                variant="contained"
              >
                Close
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Show>
    </Box>
  );
};

export default NotesManagement;
