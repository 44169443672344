import { useState } from 'react';
import { useDataProvider, useListContext } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const MAX_ACCOUNTING_EXPORT = 200;

import { downloadFile } from 'utils';
import InfoDialog from 'components/InfoDialog';

const AccountingExportButton = ({ total, ...props }) => {
  const dataProvider = useDataProvider();
  const { filterValues } = useListContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isDisabled =
    !filterValues?.contractorId ||
    !filterValues?.['dateApproved||$lte'] ||
    !filterValues?.['dateApproved||$gte'];

  const getAccountingExportReport = async () => {
    if (total > MAX_ACCOUNTING_EXPORT) {
      setIsModalOpen(true);
      return;
    }

    const response = await dataProvider.getOne<{
      id: string;
      blob: Blob;
    }>('prod-pay-master-sheet-quickbooks', {
      // @ts-expect-error `filter` is not typed
      filter: filterValues,
    });

    const blobURL = URL.createObjectURL(response.data.blob);
    downloadFile(blobURL, `accounting-report-${response.data.id}.xlsx`);
    URL.revokeObjectURL(blobURL);
  };

  return (
    <>
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={isDisabled ? handleOpen : undefined}
        title={
          <Box>
            <Typography variant="caption">Required filters:</Typography>
            <br />
            <Typography variant="caption">&apos;Contractors&apos;</Typography>
            <br />
            <Typography variant="caption">
              &apos;Completed On and Before&apos;
            </Typography>
            <br />
            <Typography variant="caption">
              &apos;Completed On and After&apos;
            </Typography>
          </Box>
        }
      >
        <span>
          <Button
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={async () => getAccountingExportReport()}
            disabled={isDisabled}
            {...props}
          >
            Accounting Export
          </Button>
        </span>
      </Tooltip>
      <InfoDialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title="Attention!"
        content="Export limit exceeded. You can export a maximum of 200 rows at a time. Please refine your selection."
      />
    </>
  );
};

export default AccountingExportButton;
