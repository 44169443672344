/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useNotify, GetOneResult, useRedirect } from 'react-admin';
import map from 'lodash/map';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  FactCheck,
  Handyman,
  HomeRepairService,
  StickyNote2,
} from 'components/Icons';
import ConfirmDialog from 'components/ConfirmDialog';
import MobileMasterSheetJobInfo from './mobile/master-sheet-job-info';
import { dataProvider } from 'providers/dataProvider';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_NOTES, CAN_EDIT_OPTIONS } from 'providers/permissions';
import MasterSheetUpload from './master-sheet-upload';
import {
  BoardFloorDto,
  BoardFloorInterface,
  BoardPurchaseDto,
  BoardTypeDto,
  BoardTypeInterface,
  BuilderDto,
  ContractorDto,
  JobDto,
  JobNoteDto,
  JobPhaseDto,
  JobPhasePhotoDto,
  JobPhaseUpdateDto,
  ModelDto,
  PhaseDto,
  PhaseTypeEnum,
  SubdivisionDto,
  UserDto,
} from '@vatos-pas/common';
import { Point } from 'geojson';
import MasterSheetBoards from './master-sheet-boards';
import MasterSheetTimeline from './master-sheet-timeline';
import MobileMasterSheetTimeline, {
  BUMP_PHASES,
} from './mobile/master-sheet-timeline';
import MasterSheetStatusBar from './master-sheet-status-bar';
import MasterSheetJobInfo from './master-sheet-job-info';
import MasterSheetRepairs from './master-sheet-repairs';
import MobileMasterSheetRepairs from './mobile/master-sheet-repairs';
import { useStyles } from './master-sheet-styles';

import isMobileHook from './../../../hooks/isMobile';

export enum TabEnum {
  Boards = 'boards',
  Timeline = 'timeline',
  Notes = 'notes',
  Repairs = 'repairs',
}

export interface FloorListRow {
  row: number;
  boards: {
    [index: string]: Pick<
      BoardFloorInterface,
      'boardTypeId' | 'floor' | 'quantity'
    >;
  };
}

export interface FloorListItem {
  floor: BoardFloorInterface['floor'];
  rows: FloorListRow[];
}

export interface BoardRows {
  [index: number]: { rows: { [index: number]: BoardFloorDto[] } };
  items?: BoardFloorDto[];
}

export interface Job {
  job: JobDto;
  subdivision: SubdivisionDto;
  builder: BuilderDto;
  supervisorUser: UserDto;
  supervisors: UserDto[];
  phases: PhaseDto[];
  jobPhase: JobPhaseDto;
  jobPhases: JobPhaseDto[];
  boardTypes: BoardTypeDto[];
  boardPurchases: BoardPurchaseDto[];
  boardReceiptFloors: BoardFloorDto[];
  boardRows: BoardRows;
  contractors: ContractorDto[];
  jobPhasePhotos: JobPhasePhotoDto[];
  jobNotes: JobNoteDto[] | null;
  model: ModelDto;
  options: any[];
  jobRepairs: any[];
  repairFormula: any[];
}

const getJob = async (
  setJob: any,
  id: string,
  setToggleGarage: any,
  setTabSelected: (tab: TabEnum) => void,
  setOptionsSelected: (options: string[]) => void,
) => {
  setJob(null);
  // set up some vars
  let job: JobDto;
  let model: ModelDto;
  let subdivision: SubdivisionDto;
  let builder: BuilderDto;
  let supervisorUser: UserDto;
  const boardTypes: BoardTypeDto[] = [];
  let boardPurchases: any[] = [];
  let boardReceiptFloors: BoardFloorDto[] = [];
  let phases: PhaseDto[];
  let jobPhase: JobPhaseDto;
  let jobPhases: JobPhaseDto[];
  const contractors: ContractorDto[] = [];
  const supervisors: UserDto[] = [];
  let jobPhasePhotos: JobPhasePhotoDto[] = [];
  const boardRows: BoardRows = { items: [] };
  let options: any = [];
  let jobOptions: any = [];
  let jobRepairs: any = [];
  let repairFormula: any = [];
  let jobNotes: JobNoteDto[] | null = null;

  // try to get the job and model
  const jobResult = await dataProvider.getOne<JobDto>('job', { id });

  if (jobResult.data) {
    job = jobResult.data;
    // get the model
    if (job?.model) {
      model = job.model;
    } else {
      throw new Error('Model not found on job');
    }
    if (job?.jobPhase) {
      jobPhase = job.jobPhase;
    } else {
      throw new Error('Board Purchases not found on job');
    }
  } else {
    throw new Error('Job not found');
  }

  // try to get the subdivision
  const subdivisionResult = await dataProvider.getOne<SubdivisionDto>(
    'subdivision',
    { id: model.subdivisionId },
  );

  if (subdivisionResult.data) {
    subdivision = subdivisionResult.data;
  } else {
    throw new Error('Subdivision not found');
  }

  const builderResult = await dataProvider.getOne<BuilderDto>('builder', {
    id: subdivision.builderId,
  });

  if (builderResult.data) {
    builder = builderResult.data;
  } else {
    throw new Error('Builder not found');
  }

  const supervisorResult = await dataProvider.getOne<UserDto>('user', {
    id: subdivision.supervisorUserId,
  });

  if (supervisorResult.data) {
    supervisorUser = supervisorResult.data;
  } else {
    throw new Error('Supervisor not found');
  }

  const phasesResult = await dataProvider.getList<PhaseDto>('phase', {
    filter: {},
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
  });

  if (phasesResult.data) {
    phases = phasesResult.data;
  } else {
    throw new Error('Phases not found');
  }

  const jobPhasesResult = await dataProvider.getList<JobPhaseDto>('job-phase', {
    filter: { jobId: id },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
  });

  const jobNotesResponse = await dataProvider.getList<JobNoteDto>('job-note', {
    filter: { jobId: id },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
  });

  if (jobNotesResponse.data) {
    jobNotes = jobNotesResponse.data;
  }

  if (jobPhasesResult.data) {
    jobPhases = jobPhasesResult.data;
    const contractorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
      jobPhase.contractorId ? jobPhase.contractorId : '',
    );

    const firstContractorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
      jobPhase.initialContractorId ? jobPhase.initialContractorId : '',
    );

    const allContractors = [
      ...new Set([...firstContractorsMapped, ...contractorsMapped]),
    ];

    const supervisorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
      jobPhase.approvingUserId ? jobPhase.approvingUserId : '',
    );

    const jobPhasesMapped = jobPhases.map((phase: JobPhaseDto) => phase.id);
    const contractorsResult = await Promise.all(
      allContractors.map((contractorId: string) => {
        if (contractorId) {
          return dataProvider.getOne<ContractorDto>('contractor', {
            id: contractorId,
          });
        }
      }),
    );
    contractorsResult.map(
      (contractorData: GetOneResult<ContractorDto> | undefined) => {
        if (contractorData) {
          contractors.push(contractorData.data);
        }
      },
    );
    const phasePhotosResult = await Promise.all(
      jobPhasesMapped.map((jobPhaseId: string) => {
        return dataProvider.getList('job-phase-photo', {
          filter: { 'jobPhaseId||$eq||': jobPhaseId },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
        });
      }),
    );
    phasePhotosResult.map((jobPhaseData: any) => {
      if (jobPhaseData) {
        jobPhasePhotos = jobPhasePhotos.concat(jobPhaseData.data);
      }
    });

    const supervisorsResult = await Promise.all(
      supervisorsMapped.map((supervisorId: string) => {
        if (supervisorId) {
          return dataProvider.getOne<UserDto>('user', {
            id: supervisorId,
          });
        }
      }),
    );
    supervisorsResult.map(
      (supervisorData: GetOneResult<UserDto> | undefined) => {
        if (supervisorData) {
          supervisors.push(supervisorData.data);
        }
      },
    );
  } else {
    throw new Error('Job phases not found');
  }

  const boardPurchasesCall = await dataProvider.getList<BoardPurchaseDto>(
    'board-purchase',
    {
      filter: { jobId: id },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'ASC' },
    },
  );

  if (boardPurchasesCall.data) {
    boardPurchases = boardPurchasesCall.data.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 0,
    );
  } else {
    throw new Error('Board Purchases not found');
  }

  const jobRepairsCall = await dataProvider.getList<PhaseDto>('job-repair', {
    filter: {
      jobId: id,
      $join: [{ field: 'jobRepairNotes' }],
    },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  if (jobRepairsCall.data) {
    jobRepairs = jobRepairsCall.data.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 0,
    );
  } else {
    throw new Error('Board Purchases not found');
  }

  const repairFormulaCall = await dataProvider.getList<any>('repair-formula', {
    filter: { active: true },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  if (repairFormulaCall.data) {
    repairFormula = repairFormulaCall.data.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 0,
    );
  } else {
    throw new Error('Board Purchases not found');
  }

  const materialsPhase = getPhase(
    job,
    phases,
    jobPhases,
    PhaseTypeEnum.Materials,
  );

  if (materialsPhase && materialsPhase.approved) {
    const boardPurchaseIdList = [
      ...new Set(
        boardPurchases.map((boardPurchase: BoardFloorDto) => boardPurchase.id),
      ),
    ];
    const boardReceiptFloorsResult = await Promise.all(
      boardPurchaseIdList.map((boardPurchaseId: BoardTypeInterface['id']) =>
        dataProvider.getList<BoardFloorDto>('board-receipt-floor', {
          filter: { boardPurchaseId },
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'id', order: 'ASC' },
        }),
      ),
    );

    if (boardReceiptFloorsResult) {
      boardReceiptFloorsResult.map(
        (boardReceiptFloor: any) =>
          (boardReceiptFloors = boardReceiptFloors.concat(
            boardReceiptFloor.data,
          )),
      );
    }
  }

  const garagePhase = getPhase(job, phases, jobPhases, PhaseTypeEnum.Garage);
  if (garagePhase) {
    setToggleGarage(garagePhase.required);
  }

  const optionsCall = await dataProvider.getList<any>('option', {
    filter: { isInternal: true },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  const jobOptionsCall = await dataProvider.getList<PhaseDto>('job-option', {
    filter: { jobId: id },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  if (jobOptionsCall.data) {
    jobOptions = jobOptionsCall.data;
  } else {
    throw new Error('Job Options not found');
  }

  if (jobOptions.length) {
    setOptionsSelected(jobOptions.map((jobOption: any) => jobOption.optionId));
  }

  if (optionsCall.data) {
    options = optionsCall.data;
  } else {
    throw new Error('Options not found');
  }

  // build the "new job"
  const newJob: Job = {
    job,
    supervisors,
    jobPhase,
    jobPhases,
    builder,
    boardRows,
    boardTypes,
    boardPurchases,
    boardReceiptFloors,
    model,
    phases,
    subdivision,
    supervisorUser,
    contractors,
    jobPhasePhotos,
    options,
    jobRepairs,
    repairFormula,
    jobNotes,
  };

  const currentPhase = phases.find(
    (phase: PhaseDto) => phase.id === jobPhase.phaseId,
  );

  const tabSelected =
    currentPhase?.phaseType === PhaseTypeEnum['Materials'] || job.pendingReview
      ? TabEnum.Boards
      : TabEnum.Timeline;

  setTabSelected(tabSelected);
  setJob(newJob);
};

const refreshNotes = (setJob: any, theJob: any) => async () => {
  let jobNotes: JobNoteDto[] | null = null;
  const jobNotesResponse = await dataProvider.getList<JobNoteDto>('job-note', {
    filter: { jobId: theJob.job.id },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
  });

  if (jobNotesResponse.data) {
    jobNotes = jobNotesResponse.data;
  } else {
    throw new Error('Job notes not found');
  }
  setJob({ ...theJob, jobNotes });
};

const refreshRepairs = (setJob: any, theJob: any) => async () => {
  let jobRepairs: any = [];
  const jobRepairsCall = await dataProvider.getList<PhaseDto>('job-repair', {
    filter: { jobId: theJob.job.id, $join: [{ field: 'jobRepairNotes' }] },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  if (jobRepairsCall.data) {
    jobRepairs = jobRepairsCall.data.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 0,
    );
  } else {
    throw new Error('Board Purchases not found');
  }
  setJob({ ...theJob, jobRepairs });
  return jobRepairs;
};

const refreshJobCall = async (setJob: any, id: string, theJob: any) => {
  // set up some vars
  let job: JobDto;

  // try to get the job and model
  const jobResult = await dataProvider.getOne<JobDto>('job', { id });

  if (jobResult.data) {
    job = jobResult.data;
  } else {
    throw new Error('Job not found');
  }

  // build the "new job"
  const newJob: Job = { ...theJob, job };

  setJob(newJob);
};

const getPhase = (
  job: JobDto,
  phases: PhaseDto[],
  jobPhases: JobPhaseDto[],
  phaseType: PhaseTypeEnum,
): JobPhaseDto => {
  if (job && phases) {
    const phase = phases.find(phase => phase.phaseType === phaseType);

    if (phase) {
      const jobPhase = jobPhases.find(
        (jobPhase: JobPhaseDto) => jobPhase.phaseId === phase.id,
      );
      if (jobPhase) {
        return jobPhase;
      }
    }
  }

  throw new Error('Job phase not found.');
};

export const MasterSheetEdit: FC<any> = props => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [theJob, setJob] = useState<Job>();
  const [geoLocation, setGeoLocation] = useState<Point['coordinates']>([]);
  const [geoLocationError, setGeoLocationError] = useState<string>('');
  const [tabSelected, setTabSelected] = useState<TabEnum>(TabEnum.Boards);
  const [toggleGarage, setToggleGarage] = useState<boolean>(false);
  const [openConfirmGoBack, setOpenConfirmGoBack] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [isViewPhotos, setIsViewPhotos] = useState(false);
  const [approveMessage, setApproveMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<JobPhaseDto>();
  const [notes, setNotes] = useState<EditorState>(EditorState.createEmpty());
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [initialRepairValue, setInitialRepairValue] = useState<
    string | undefined
  >();

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(setPosition, retryLocation, {
      maximumAge: Infinity,
      timeout: 6000,
    });
  };

  const setPosition = (position: any) =>
    setGeoLocation([position.coords.latitude, position.coords.longitude]);

  const refreshJobPhases = async () => {
    let jobPhases: JobPhaseDto[];
    let jobPhase: JobPhaseDto;
    let jobPhasePhotos: JobPhasePhotoDto[] = [];
    const contractors: ContractorDto[] = [];
    const supervisors: UserDto[] = [];

    const jobResult = await dataProvider.getOne<JobDto>('job', {
      id: props.id,
    });
    const job: JobDto = jobResult.data;

    if (job?.jobPhase) {
      jobPhase = job.jobPhase;
    } else {
      throw new Error('Board Purchases not found on job');
    }

    const jobPhasesResult = await dataProvider.getList<JobPhaseDto>(
      'job-phase',
      {
        filter: { jobId: props.id },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      },
    );

    if (jobPhasesResult.data) {
      jobPhases = jobPhasesResult.data;

      const phasePhotosResult = await Promise.all(
        jobPhases.map((jobPhase: JobPhaseDto) => {
          return dataProvider.getList('job-phase-photo', {
            filter: { 'jobPhaseId||$eq||': jobPhase.id },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
          });
        }),
      );
      phasePhotosResult.map((jobPhaseData: any) => {
        if (jobPhaseData) {
          jobPhasePhotos = jobPhasePhotos.concat(jobPhaseData.data);
        }
      });

      const supervisorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
        jobPhase.approvingUserId ? jobPhase.approvingUserId : '',
      );

      const supervisorsResult = await Promise.all(
        supervisorsMapped.map((id: string) => {
          if (id) {
            return dataProvider.getOne<UserDto>('user', { id });
          }
        }),
      );
      supervisorsResult.map(
        (supervisorData: GetOneResult<UserDto> | undefined) => {
          if (supervisorData) {
            supervisors.push(supervisorData.data);
          }
        },
      );
    } else {
      throw new Error('Job phases not found');
    }

    const contractorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
      jobPhase.contractorId ? jobPhase.contractorId : '',
    );
    const firstContractorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
      jobPhase.initialContractorId ? jobPhase.initialContractorId : '',
    );
    const allContractors = [
      ...new Set([...firstContractorsMapped, ...contractorsMapped]),
    ];
    const contractorsResult = await Promise.all(
      allContractors.map((id: string) => {
        if (id) {
          return dataProvider.getOne<ContractorDto>('contractor', { id });
        }
      }),
    );
    contractorsResult.map(
      (contractorData: GetOneResult<ContractorDto> | undefined) => {
        if (contractorData) {
          contractors.push(contractorData.data);
        }
      },
    );

    if (theJob) {
      const newJob = {
        ...theJob,
        jobPhases,
        job,
        supervisors,
        jobPhase,
        jobPhasePhotos,
        contractors,
      };
      setJob(newJob);
    }
  };

  const approveJob = async () => {
    const jobPhase = theJob?.jobPhase;
    if (jobPhase) {
      const params: any = {
        id: jobPhase.id,
        data: { approved: true },
        previousData: jobPhase,
      };
      if (geoLocation.length) {
        params.data.approvedGeo = { type: 'Point', coordinates: geoLocation };
      }
      await dataProvider
        .update('job-phase', params)
        .then(() => {
          notify('Job Approved!');
          refreshJobPhases();
          onClose();
          setUploadModalOpen(false);
        })
        .catch(error => {
          notify(`Approve Job Error: ${error.message}`, 'warning');
        });
    }
  };

  const jobPhase = theJob?.jobPhase;
  const goBackJob = async () => {
    if (jobPhase) {
      const params = {
        id: jobPhase.id,
        data: {} as JobPhaseUpdateDto,
        previousData: jobPhase,
      };
      if (jobPhase.approved || (!jobPhase.contractorId && !jobPhase.requested))
        return;

      const currentPhase = theJob?.phases.find(
        phase => phase.id === jobPhase.phaseId,
      );

      if (currentPhase && BUMP_PHASES.includes(currentPhase.phaseType)) {
        params.data.contractorId = null;
        params.data.requested = false;
      } else {
        if (jobPhase.contractorId) {
          params.data.contractorId = null;
        } else if (jobPhase.requested) {
          params.data.requested = false;
        }
      }

      await dataProvider
        .update('job-phase', params)
        .then(() => {
          notify('Go back with success!');
          refreshJobPhases();
          onClose();
        })
        .catch(error => {
          notify(`Go Back error: ${error.message}`, 'warning');
        });
    }
  };

  const onClose = () => {
    setOpenConfirmApprove(false);
    setOpenConfirmGoBack(false);
  };

  const retryLocation: PositionErrorCallback = (error: any) => {
    if (error.code === 3) {
      return getLocation();
    }
    if (error.code === 1) {
      return setGeoLocationError('You need to enable your location');
    }
    setGeoLocationError('');
  };

  useEffect(() => {
    getJob(
      setJob,
      props.id,
      setToggleGarage,
      setTabSelected,
      setOptionsSelected,
    );
    getLocation();
  }, []);

  useEffect(() => {
    const jobLoaded = theJob && theJob.job && theJob.job.id;

    const checkParams = () => {
      if (!jobLoaded) return;
      try {
        const searchParams = new URLSearchParams(props.location.search);
        const repairId = searchParams.get('repairId');
        if (repairId === null) return;
        setTabSelected(TabEnum.Repairs);
        setInitialRepairValue(repairId);
      } catch (error) {
        console.log(error);
      }
    };

    checkParams();
  }, [theJob, props.location.search]);

  const isInWarranty = () => {
    const actualPhase = theJob?.phases.find(
      (phase: PhaseDto) => phase.id === theJob.jobPhase.phaseId,
    );
    return actualPhase && actualPhase.priority >= 600;
  };

  const showBump = (bumpType: PhaseTypeEnum) => {
    const actualPhase = theJob?.phases.find(
      (phase: PhaseDto) => phase.id === theJob.jobPhase.phaseId,
    );
    if (bumpType === PhaseTypeEnum['Bump4']) {
      return (
        (actualPhase && actualPhase.priority > 800) ||
        (actualPhase &&
          actualPhase.priority === 800 &&
          theJob?.jobPhase.approved)
      );
    }
    if (bumpType === PhaseTypeEnum['Bump5']) {
      return (
        (actualPhase && actualPhase.priority > 900) ||
        (actualPhase &&
          actualPhase.priority === 900 &&
          theJob?.jobPhase.approved)
      );
    }
    return false;
  };

  const changeNotes = async () => {
    setLoading(true);

    const plainText =
      convertToHTML(notes.getCurrentContent()).trim() !== '<p></p>'
        ? convertToHTML(notes.getCurrentContent()).trim()
        : '';

    try {
      if (theJob && hasPermission(CAN_EDIT_NOTES)) {
        const formattedOptions = optionsSelected.map(optionId => ({
          optionId,
        }));

        await dataProvider.create('job-note', {
          data: {
            jobId: theJob.job.id,
            ...(plainText && { note: plainText }),
            ...(formattedOptions.length && {
              jobNoteOptions: formattedOptions,
            }),
          },
        });

        await refreshNotes(setJob, theJob)();

        setNotes(EditorState.createEmpty());
      }
    } catch (error) {
      return notify(`Update Job Error: ${error.message}`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const getCheckedOption = option => optionsSelected.indexOf(option.id) !== -1;

  const { hasPermission } = PermissionsProvider.useContainer();

  const onChangeOption = option => {
    const newOptionsSelected = [...optionsSelected];
    const index = newOptionsSelected.indexOf(option.id);
    if (index === -1) {
      newOptionsSelected.push(option.id);
    } else {
      newOptionsSelected.splice(index, 1);
    }
    setOptionsSelected(newOptionsSelected);
  };

  const isWarranty = window.location.href.indexOf('job-warranty-sheet') !== -1;

  const checkHouse = async checked => {
    const params: any = {
      id: theJob?.job.id,
      data: { houseMarked: checked },
      previousData: theJob?.job,
    };
    await dataProvider
      .update('job', params)
      .then(() => {
        const messageMarked = 'House marked!';
        const messageUnMarked = 'House unmarked!';
        notify(checked ? messageMarked : messageUnMarked);
        refreshJobCall(setJob, props.id, theJob);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(`House mark Error: ${error.message}`, 'warning');
      });
  };

  const updateJob = () =>
    getJob(
      setJob,
      props.id,
      setToggleGarage,
      setTabSelected,
      setOptionsSelected,
    );

  const changeTab = (newTab: TabEnum) => setTabSelected(newTab);

  const isMobile = isMobileHook();

  return (
    <Fragment>
      <Box {...props} width="100%" maxWidth="100vw">
        <Box className={classes.createBox}>
          {theJob && theJob.job && theJob.job.id ? (
            <Fragment>
              <Box mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ChevronLeftIcon />}
                  onClick={() =>
                    redirect(
                      isWarranty ? '/job-warranty-sheet' : '/job-master-sheet',
                    )
                  }
                >
                  Go Back
                </Button>
              </Box>
              <MasterSheetStatusBar
                theJob={theJob}
                getPhase={getPhase}
                showBump={showBump}
                isInWarranty={isInWarranty}
              />

              {isMobile ? (
                <MobileMasterSheetJobInfo
                  checkHouse={checkHouse}
                  theJob={theJob}
                />
              ) : (
                <MasterSheetJobInfo checkHouse={checkHouse} theJob={theJob} />
              )}

              <Box className={classes.mobileBorderlessWhiteBox} overflow="auto">
                <AppBar position="static">
                  <Tabs
                    value={tabSelected}
                    onChange={(_event, newTab) => setTabSelected(newTab)}
                    aria-label="Vatos Tabs on House Report"
                    centered
                    indicatorColor="secondary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    classes={{
                      root: classes.tabs,
                      indicator: classes.indicator,
                    }}
                  >
                    <Tab
                      label="Boards"
                      value={TabEnum.Boards}
                      className={classes.tabsText}
                      icon={isMobile ? <FactCheck /> : undefined}
                    />
                    <Tab
                      label="Timeline"
                      value={TabEnum.Timeline}
                      className={classes.tabsText}
                      icon={isMobile ? <Handyman /> : undefined}
                    />
                    <Tab
                      label="Notes"
                      value={TabEnum.Notes}
                      className={classes.tabsText}
                      icon={isMobile ? <StickyNote2 /> : undefined}
                    />
                    <Tab
                      label="Repairs"
                      value={TabEnum.Repairs}
                      className={classes.tabsText}
                      icon={isMobile ? <HomeRepairService /> : undefined}
                    />
                  </Tabs>
                </AppBar>
                <Box width="100%" overflow="auto">
                  {tabSelected === TabEnum.Boards && (
                    <Box>
                      <MasterSheetBoards
                        loading={loading}
                        theJob={theJob}
                        geoLocationError={geoLocationError}
                        updateJob={updateJob}
                        changeTab={changeTab}
                        history={props.history}
                      />
                    </Box>
                  )}
                  {tabSelected === TabEnum.Timeline && (
                    <>
                      {isMobile ? (
                        <MobileMasterSheetTimeline
                          theJob={theJob}
                          geoLocationError={geoLocationError}
                          getPhase={getPhase}
                          isInWarranty={isInWarranty}
                          showBump={showBump}
                          setOpenConfirmGoBack={setOpenConfirmGoBack}
                          refreshJobPhases={refreshJobPhases}
                          toggleGarage={toggleGarage}
                          setToggleGarage={setToggleGarage}
                          setIsViewPhotos={setIsViewPhotos}
                          setUploadModalOpen={setUploadModalOpen}
                          setSelectedPhase={setSelectedPhase}
                        />
                      ) : (
                        <MasterSheetTimeline
                          theJob={theJob}
                          geoLocationError={geoLocationError}
                          getPhase={getPhase}
                          isInWarranty={isInWarranty}
                          showBump={showBump}
                          setOpenConfirmGoBack={setOpenConfirmGoBack}
                          refreshJobPhases={refreshJobPhases}
                          toggleGarage={toggleGarage}
                          setToggleGarage={setToggleGarage}
                          setIsViewPhotos={setIsViewPhotos}
                          setUploadModalOpen={setUploadModalOpen}
                          setSelectedPhase={setSelectedPhase}
                        />
                      )}
                    </>
                  )}
                  {tabSelected === TabEnum.Notes && (
                    <Box width="100%">
                      <Box className={classes.titleTabNoBorder} px={2}>
                        <Typography className={classes.titleFont}>
                          Notes
                        </Typography>
                      </Box>
                      <Box className={classes.optionsSection} px={2}>
                        {theJob.options.map(option => (
                          <Box key={option.id} mr={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={getCheckedOption(option)}
                                  onChange={() => onChangeOption(option)}
                                  disabled={!hasPermission(CAN_EDIT_OPTIONS)}
                                  name="isMonday"
                                  color="primary"
                                />
                              }
                              label={option.name}
                            />
                          </Box>
                        ))}
                      </Box>
                      <Box mt={2}>
                        {hasPermission(CAN_EDIT_NOTES) ? (
                          <Editor
                            editorState={notes}
                            editorClassName={classes.editor}
                            toolbar={{
                              options: ['inline', 'blockType', 'list'],
                              fontSize: {
                                options: [12, 14, 16, 18, 24, 30],
                              },
                              inline: {
                                options: ['bold', 'italic', 'underline'],
                              },
                              blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3'],
                              },
                              list: {
                                options: ['unordered', 'ordered'],
                              },
                            }}
                            onEditorStateChange={setNotes}
                          />
                        ) : (
                          <div
                            className="preview"
                            dangerouslySetInnerHTML={{
                              __html: convertToHTML(notes.getCurrentContent()),
                            }}
                          ></div>
                        )}
                      </Box>
                      {hasPermission(CAN_EDIT_NOTES) && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          onClick={changeNotes}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress
                              classes={{ colorPrimary: classes.spinner }}
                              size={24}
                            />
                          ) : (
                            'Submit'
                          )}
                        </Button>
                      )}

                      {!!theJob.jobNotes?.length && (
                        <Box pt={3}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Notes History
                          </Typography>
                          <Box>
                            {theJob.jobNotes.map((item, index) => (
                              <Box key={item.id} mb={2}>
                                {index > 0 && <Divider />}
                                <Box pt={2}>
                                  <Box>
                                    {item.user?.firstName &&
                                      item.user.lastName && (
                                        <Box>
                                          <Typography>{`${
                                            item.user.firstName
                                          } ${item.user.lastName} - ${new Date(
                                            item.createdAt,
                                          ).toLocaleTimeString(
                                            'en-US',
                                          )} ${new Date(
                                            item.createdAt,
                                          ).toLocaleDateString(
                                            'en-US',
                                          )}`}</Typography>
                                        </Box>
                                      )}
                                    {!!item.jobNoteOptions?.length && (
                                      <Typography>
                                        Job Options:{' '}
                                        {map(
                                          item.jobNoteOptions,
                                          'option.name',
                                        ).join(', ')}
                                      </Typography>
                                    )}
                                  </Box>
                                  {item.note && (
                                    <Box mt={2}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.note,
                                        }}
                                        className="preview"
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  {tabSelected === TabEnum.Repairs && (
                    <>
                      {isMobile ? (
                        <MobileMasterSheetRepairs
                          refreshRepairs={refreshRepairs(setJob, theJob)}
                          theJob={theJob}
                          initialValue={initialRepairValue}
                        />
                      ) : (
                        <MasterSheetRepairs
                          refreshRepairs={refreshRepairs(setJob, theJob)}
                          theJob={theJob}
                          initialValue={initialRepairValue}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Fragment>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              flexDirection="column"
            >
              <CircularProgress />
              <Typography className={classes.loadingText}>
                Loading Job Info
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmDialog
        open={openConfirmApprove}
        title={approveMessage}
        confirmLabel="Approve Phase"
        handleClose={onClose}
        onConfirm={approveJob}
      />
      <ConfirmDialog
        open={openConfirmGoBack}
        title={`Are you sure that you want to go back?`}
        handleClose={onClose}
        onConfirm={goBackJob}
      />
      {theJob && theJob.job && theJob.job.id && selectedPhase ? (
        <MasterSheetUpload
          jobPhaseId={theJob?.jobPhase.id}
          open={uploadModalOpen}
          handleClose={() => setUploadModalOpen(false)}
          setOpenConfirmApprove={setOpenConfirmApprove}
          isViewPhotos={isViewPhotos}
          setApproveMessage={setApproveMessage}
          approveJob={approveJob}
          selectedPhase={selectedPhase}
        />
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
